"use client"

import { useCallback, useEffect, useState } from "react";
import { optimizelyClient } from "./optimizelyClient";
import crypto from "crypto";

export const useOptimizelyRollout = (flagKey: string) => {
  const [variation, setVariation] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isReady, setIsReady] = useState(false);

  const getVariationByRule = useCallback(async () => {
    const storedUserId = localStorage.getItem("user_id");
    const newUserId = storedUserId || crypto.randomBytes(20).toString("hex");
    if (!storedUserId) {
      localStorage.setItem("user_id", newUserId);
    }

    const variationResponse = optimizelyClient.decide(flagKey, [], newUserId);
    setVariation(variationResponse.variationKey);
    setIsLoading(false);
  }, [flagKey]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    const checkReady = () => {
      if (optimizelyClient.isReady()) {
        setIsReady(true);
      } else {
        timeout = setTimeout(checkReady, 100);
      }

      return () => {
        if (timeout) clearTimeout(timeout);
      };
    };

    checkReady();
  }, []);

  useEffect(() => {
    if (isReady) getVariationByRule();
  }, [isReady, getVariationByRule]);

  return {
    variation,
    isLoading,
  };
};
